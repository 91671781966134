
var _push_event_to_google_analytics = function (name, parameter_1, label_1, parameter_2, label_2, parameter_3, label_3) {
    dataLayer.push({
        'event': 'gaEvent',
        'eventName': name,
        'eventParameterName_1': parameter_1,
        'eventParameterLabel_1': label_1,
        'eventParameterName_2': parameter_2,
        'eventParameterLabel_2': label_2,
        'eventParameterName_3': parameter_3,
        'eventParameterLabel_3': label_3
    });
};

push_event_to_google_analytics_searchBtn = function(value) {
    _push_event_to_google_analytics('store_locator_search', 'search_terms', value);
}

push_event_to_google_analytics_favorite_store = function(value) {
    _push_event_to_google_analytics('add_favorite_store', 'store_name', value);
}

push_event_to_google_analytics_see_all_recipes = function() {
    _push_event_to_google_analytics('view_recipe', 'recipe_name','see all recipes');
}

push_event_to_google_analytics_cookie_banner_link = function() {
    _push_event_to_google_analytics('user_consent', 'privacy_action','sticky banner - link - redirection to privacy page');
}

push_event_to_google_analytics_cookie_banner_btn = function() {
    _push_event_to_google_analytics('user_consent', 'privacy_action','sticky banner - button - acknowledgement');
}

